// fields to be set from java

class ZIDXAccountListingIndexFileFieldEdit implements ZIDXPage {
    constructor() {
    }

    getPageClass() {
        return "zidxAccountListingIndexFileFieldEditContainer";
    }

    getMatchURL() {
        return "/account/data-feeds/listing-index/file/fields/edit";
    }

    render() {
        window.ZIDXOptions.loadFunctions.push(function () {
            const $ = ZIDX.$;
            const listing_index_file_field_id = $('#listing_index_file_field_id').val();
            const listing_index_file_id = $('#listing_index_file_id').val();
            const fieldNameInput = $("#listing_index_file_field_name");
            const sourceCombinedField=$('#source_combined_field');

            const labelInput = $('#listing_index_file_field_label');
            const labelPlaceholder = labelInput.attr('placeholder');
            const assignmentTypeInput = $('input[type=radio][name=listing_index_file_field_assignment_type]');
            const assignmentTypeChecked = $('input[type=radio][name=listing_index_file_field_assignment_type]:checked');

            fieldNameInput.on('change', function (this: any) {
                let link=`/account/data-feeds/listing-index/file/fields/edit?listing_index_file_field_id=${listing_index_file_field_id}&listing_index_file_id=${listing_index_file_id}&listing_index_file_field_name=${encodeURIComponent(this.value)}&listing_index_file_field_assignment_type=${$('input[name=listing_index_file_field_assignment_type]:checked').val()}`;
                console.log("link", link);
                window.location.href=link;
            });
            if(fieldNameInput.val()=="" && sourceCombinedField.val()==""){
                // hide interface
                $("#fieldFormContainerFirst").hide();
                $("#fieldFormContainer").hide();
                $("#fieldFormContainerPreview").hide();
            }

            function onAssignmentTypeChange(value: string) {
                if(value=='2') {
                    $('#listing_index_file_field_heading_list_container').show();
                }else{
                    $('#listing_index_file_field_heading_list_container').hide();
                }
                if (value == '1' || value == '2' || value == '3') {
                    if (value == '3') {
                        $('#listing_index_file_field_label_container').hide()
                        $("#field_name_container").show()
                        $('#delimiter_container').hide();
                        $('#exclude_container').show();
                    } else {
                        $('#listing_index_file_field_label_container').show()
                        if (value == '1') {
                            $('#listing_index_file_field_label_label').text('Header: ')
                            $("#field_name_container").hide()
                            labelInput.attr('placeholder', '')
                        }else {
                            $('#listing_index_file_field_label_label').text('Label: ')
                            $("#field_name_container").show()
                            labelInput.attr('placeholder', labelPlaceholder)
                        }
                        $('#delimiter_container').hide();
                        $('#exclude_container').hide();
                    }
                    $('#unique_values_container').hide();
                    $('#source_combined_field_container').hide();
                    $('#destination_field_container').hide();
                    $('#destination_value_field_container').hide();
                    $('#source_specific_field_container').hide();
                    $('#exclude_container').hide();
                } else {
                    $('#listing_index_file_field_label_container').hide()
                    $("#field_name_container").show()
                    $('#delimiter_container').show();
                    if (value == '4') {
                        $('#unique_values_container').show();
                        $('#source_combined_field_container').hide();
                        $('#destination_field_container').show();
                        $('#destination_value_field_container').hide();
                        $('#source_specific_field_container').hide();
                        $('#exclude_container').show();
                    } else if (value == '5') {
                        $('#unique_values_container').show();
                        $('#source_combined_field_container').show();
                        $('#destination_field_container').show();
                        $('#destination_value_field_container').hide();
                        $('#source_specific_field_container').hide();
                        $('#exclude_container').hide();
                    } else if (value == '6') {
                        $('#unique_values_container').show();
                        $('#source_combined_field_container').hide();
                        $('#destination_field_container').show();
                        $('#destination_value_field_container').show();
                        $('#source_specific_field_container').show();
                        $('#exclude_container').hide();
                    }
                }
            }

            onAssignmentTypeChange(assignmentTypeChecked.val())
            assignmentTypeInput.change(function (this: any) {
                onAssignmentTypeChange(this.value)
            });

            $('#control_field_add').on('click', function () {
                const value = $('#controls_source_field').val()
                if (value) {
                    const input = $('#source_combined_field');
                    input.val(function (this: any) {
                        if (this.value && this.value.trim())
                            return `${this.value} + ${value}`
                        else return `${value}`
                    });
                    input.get(0).scrollLeft = input.get(0).scrollWidth;
                }
            })
            $('#control_text_add').on('click', function () {
                const value = $('#control_text').val()
                if (value) {
                    const input = $('#source_combined_field');
                    input.val(function (this: any) {
                        if (this.value && this.value.trim())
                            return `${this.value} + "${value}"`
                        else return `"${value}"`
                    });
                    input.get(0).scrollLeft = input.get(0).scrollWidth;
                }
            })

            $('#listing_index_file_field_source_value').on('change', function (this: any) {
                if (this.value == '__other__') {
                    $('#source_other_value_field_container').show();
                } else {
                    $('#source_other_value_field_container').hide();
                }
            })
                .trigger('change')

        })
    }
}